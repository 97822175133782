import { useShow } from "@pankod/refine-core";
import { Text, Title, Show } from "@pankod/refine-mantine";
import { ICreateAgent } from "./create";
export type IAgent = Omit<ICreateAgent, 'agentNumber'> & {
    id: string,
    agentAccount: {
        agentNumber: string;
    }
}

export const AgentShow: React.FC = () => {
    const { queryResult } = useShow<IAgent>({
        metaData: {
            fields: [
                "id",
                "name",
                "phoneNumber",
                {
                    agentAccount: [
                        "agentNumber",
                    ],
                },
            ],
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Title order={5}>Id</Title>
            <Text mt="sm">{record?.id}</Text>

            <Title mt="sm" order={5}>
                Name
            </Title>
            <Text mt="sm">
                {record?.name}
            </Text>

            <Title mt="sm" order={5}>
                Phone Number
            </Title>
            <Text mt="sm">
                {record?.phoneNumber}
            </Text>

            <Title mt="sm" order={5}>
                Agent Number
            </Title>
            <Text mt="sm">
                {record?.agentAccount?.agentNumber}
            </Text>

        </Show>
    );
};
