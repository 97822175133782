import { gql } from "@apollo/client";

export const GET_EXCHANGE_RATE = gql`
  query exchangeRate($id: String!) {
    exchangeRate(id: $id) {
      id
      fromCurrencyId
      toCurrencyId
      rate
      createdAt
    }
  }
`;
