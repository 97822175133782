import { Text, Title, Show } from "@pankod/refine-mantine";
import { GET_EXCHANGE_RATE } from "graphql/exchange-rates/get-exchange-rate.query";
import { useParams } from "@pankod/refine-react-router-v6";
import { useQuery } from "@apollo/client";

export const ExchangeRateShow: React.FC = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_EXCHANGE_RATE, {
    variables: { id },
  });

  return (
    <Show isLoading={loading}>
      <Title order={5}>Id</Title>
      <Text mt="sm">{data?.exchangeRate.id}</Text>

      <Title mt="sm" order={5}>
        From Currency
      </Title>
      <Text mt="sm">{data?.exchangeRate.fromCurrencyId}</Text>

      <Title mt="sm" order={5}>
        To Currency
      </Title>
      <Text mt="sm">{data?.exchangeRate.toCurrencyId}</Text>

      <Title mt="sm" order={5}>
        Rate
      </Title>
      <Text mt="sm">{data?.exchangeRate.rate}</Text>

      <Title mt="sm" order={5}>
        Created At
      </Title>
      <Text mt="sm">{new Date(data?.exchangeRate.createdAt).toLocaleString()}</Text>
    </Show>
  );
};
