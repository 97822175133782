import {
  Edit,
  TextInput,
  useForm,
  Select,
  NumberInput,
} from "@pankod/refine-mantine";
import {
  currencyIdTypes,
  ICreateInternetPackage,
  packageTypes,
  providerNameTypes,
} from "./create";
import { internetPackageFields } from "./list";
import { HttpError } from "@pankod/refine-core";

interface IUpdateInternetPackage
  extends Omit<ICreateInternetPackage, "type" | "currencyId" | "providerName"> {
  type: string;
  currencyId: string;
  providerName: string;
}

export const InternetPackageEdit: React.FC = () => {
  const {
    values,
    setValues,
    getInputProps,
    saveButtonProps,
    refineCore: { queryResult },
  } = useForm<IUpdateInternetPackage, HttpError>({
    initialValues: {
      ...internetPackageFields,
    },
    refineCoreProps: {
      queryOptions: {
        retry: 3,
      },
      metaData: {
        fields: [
          "id",
          "name",
          "price",
          "type",
          "currencyId",
          "providerName",
          "ussdCode",
        ],
      },
    },
    transformValues(values: any) {
      delete values.id;
      return {
        ...values,
      };
    },
    validate: {},
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <form>
        <TextInput
          mt={8}
          label="Name"
          placeholder="name"
          defaultValue={queryResult?.data?.data.name}
          {...getInputProps("name")}
        />

        <NumberInput
          mt={8}
          label="Price"
          placeholder="price"
          defaultValue={queryResult?.data?.data.price}
          {...getInputProps("price")}
        />

        <Select
          mt={8}
          label="Type"
          placeholder="Select a type"
          defaultValue={queryResult?.data?.data.type}
          {...getInputProps("type")}
          onChange={(e) => setValues({ ...values, type: e })}
          data={packageTypes}
        />

        <Select
          mt={8}
          label="Currency"
          placeholder="Select a currency"
          defaultValue={queryResult?.data?.data.currencyId}
          {...getInputProps("currencyId")}
          onChange={(e) => setValues({ ...values, currencyId: e })}
          data={currencyIdTypes}
        />

        <Select
          mt={8}
          label="Provider"
          placeholder="Select a provider"
          defaultValue={queryResult?.data?.data.providerName}
          {...getInputProps("providerName")}
          onChange={(e) => setValues({ ...values, providerName: e })}
          data={providerNameTypes}
        />

        <TextInput
          mt={8}
          label="USSD code"
          placeholder="ussdCode"
          defaultValue={queryResult?.data?.data.ussdCode}
          {...getInputProps("ussdCode")}
        />
      </form>
    </Edit>
  );
};
