import { currencyTypes, ExchangeRateCreateInput } from "./create";
import { useNavigation, useNotification } from "@pankod/refine-core";
import { Edit, Select, NumberInput } from "@pankod/refine-mantine";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_EXCHANGE_RATE } from "graphql/exchange-rates/update-exchange-rate.mutation";
import { useEffect, useState } from "react";
import { GET_EXCHANGE_RATE } from "graphql/exchange-rates/get-exchange-rate.query";
import { useParams } from "@pankod/refine-react-router-v6";

interface ExchangeRateUpdateInput extends ExchangeRateCreateInput {}

export const ExchangeRateEdit: React.FC = () => {
  const { id } = useParams();
  const { push } = useNavigation();
  const { open } = useNotification();

  const [inputs, setInputs] = useState<ExchangeRateUpdateInput>({
    fromCurrencyId: "",
    toCurrencyId: "",
    rate: 0,
  });

  const { data } = useQuery(GET_EXCHANGE_RATE, {
    variables: { id },
  });

  const [updateExchangeRate, { loading }] = useMutation(UPDATE_EXCHANGE_RATE, {
    refetchQueries: [
      { query: GET_EXCHANGE_RATE, variables: { id } },
      "exchangeRate",
    ],
  });
  const [availableCurrencies, setAvailableCurrencies] =
    useState<string[]>(currencyTypes);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const noneIsEmpty = Object.values(inputs).every((value) => value !== "");

    try {
      if (noneIsEmpty) {
        await updateExchangeRate({
          variables: {
            input: {
              data: { ...inputs },
              where: { id },
            },
          },
        });
        open?.({
          type: "success",
          message: "Successfully updated exchange rate",
        });
        push("/exchangeRates");
      } else
        open?.({
          type: "error",
          message: "Make sure all fields have data",
        });
    } catch (e: any) {
      open?.({
        type: "error",
        message: e.message,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setInputs({
        fromCurrencyId: data.exchangeRate.fromCurrencyId,
        toCurrencyId: data.exchangeRate.toCurrencyId,
        rate: +data.exchangeRate.rate,
      });
    }
  }, [data]);

  useEffect(() => {
    if (inputs.fromCurrencyId)
      setAvailableCurrencies(
        currencyTypes.filter((type) => type !== inputs.fromCurrencyId)
      );
    else setAvailableCurrencies(currencyTypes);
  }, [inputs.fromCurrencyId]);

  return (
    <Edit isLoading={loading} saveButtonProps={{ onClick: handleSubmit }}>
      <form>
        <Select
          mt={8}
          label="From Currency"
          placeholder="Select currency"
          value={inputs.fromCurrencyId}
          onChange={(e: string) => setInputs({ ...inputs, fromCurrencyId: e })}
          data={currencyTypes}
        />

        <Select
          mt={8}
          label="To Currency"
          placeholder="Select currency"
          value={inputs.toCurrencyId}
          onChange={(e: string) => setInputs({ ...inputs, toCurrencyId: e })}
          data={availableCurrencies}
        />

        <NumberInput
          mt={8}
          label="Rate"
          placeholder="rate"
          value={inputs.rate}
          onChange={(e: number) => setInputs({ ...inputs, rate: e })}
        />
      </form>
    </Edit>
  );
};
