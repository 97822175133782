import { HttpError, useList, useUpdate } from "@pankod/refine-core";
import { Edit, TextInput, Title, useForm, Text, Select, Button, Container, Card, Pagination, Center, usePagination } from "@pankod/refine-mantine";
import { toString } from "lodash";
import cn from "classnames";
const formatter = new Intl.NumberFormat('en-US', {});

const phoneNumberBasedOnPaymentType = (paymentType: string, thirdParty: {
    senderAccountNumber: string;
    receiverAccountNumber: string;
}) => {
    if (paymentType === "DEPOSIT") {
        return thirdParty.senderAccountNumber.replace("+252", "")
    }
    return thirdParty.receiverAccountNumber.replace("+252", "")

}

const accountIdBasedOnPaymentType = (paymentType: string, data: {
    senderId: string;
    recepientId: string;
}) => {
    if (paymentType === "DEPOSIT") {
        return data.senderId
    }
    return data.recepientId


}
type UpdateTransaction = {
    amount: number;
    thirdParty: {
        senderAccountNumber: string;
        receiverAccountNumber: string;
        transactionId: string;
    }
}
type ITransaction = {
    id: string;
    transactionId: string;
    paymentType: string;
    amount: number;
    status: string;
    currencyId: string;
    senderId: string;
    recepientId: string;
    createdAt: string;
    metadata: {
        thirdParty: {
            senderAccountNumber: string;
            senderAccountName: string;
            receiverAccountNumber: string;
            transactionId: string;
            isAutomated?: boolean;
            automationStatus?: string;
            receiverAccountName?: string;
            invoiceId?: string;
            provider?: string;
            ussdCode?:string
        }
    }

}

const TextField: React.FC<{ label: string; value?: string }> = ({
    label,
    value,
}) => (
    <><Title order={5}>{label}</Title><Text mt="sm">{value}</Text></>
)

export const TransactionEdit: React.FC = () => {
    const { mutate } = useUpdate();
    const { active, setPage } = usePagination({ total: 10, initialPage: 1 });

    const {
        saveButtonProps,
        getInputProps,

        refineCore: { queryResult, formLoading },

    } = useForm<ITransaction, HttpError>({
        initialValues: {
            amount: 0,
            metadata: {
                thirdParty: {
                    senderAccountNumber: "",
                    senderAccountName: "",
                    receiverAccountNumber: "",
                    transactionId: "",
                    isAutomated: false,
                }
            }

        },
        refineCoreProps: {
            queryOptions: {
                retry: 3,
            },
            metaData: {
                fields: [
                    "id",
                    "transactionId",
                    "paymentType",
                    "amount",
                    "status",
                    "currencyId",
                    "senderId",
                    "recepientId",
                    "createdAt",
                    {
                        metadata: [{
                            thirdParty: ["senderAccountNumber","ussdCode", "senderAccountName","receiverAccountNumber", "transactionId", "isAutomated", "automationStatus", "receiverAccountName", "provider", "invoiceId"]
                        }]
                    }
                ],
            },
        },

        validate: {},
        transformValues(values: any) {
            if (values.metadata.thirdParty.isAutomated === undefined) {
                return {
                    ...values,
                }
            }
            return {
                ...values,

            };
        },
    });

    const { data, } = useList<{
        transactionCode: string;
        raw: string;
        createdAt: string;
        resolved: boolean;

    }>({
        resource: "sms",
        queryOptions: {
            enabled: !formLoading,
            initialData: {
                data: [],
                total: 0,
            }
        },
        metaData: {
            fields: ["transactionCode", "raw", "resolved", "createdAt"],
            operation: 'sms',
        },
        config: {
            pagination: {
                current: active,
                pageSize: 3,
            },
            filters: [
                {
                    field: 'filter',
                    operator: 'eq',
                    value: 'OR',
                },

                {
                    field: 'provider',
                    operator: 'eq',
                    value: queryResult?.data?.data.metadata.thirdParty.provider,
                },
                {
                    field: 'amount',
                    operator: 'eq',
                    value: formatter.format(((queryResult?.data?.data.amount || 0) / 100)),
                },
                {
                    field: 'phoneNumber',
                    operator: 'eq',
                    value: phoneNumberBasedOnPaymentType(queryResult?.data?.data.paymentType || 'DEPOSIT', queryResult?.data?.data.metadata.thirdParty || {
                        senderAccountNumber: "",
                        receiverAccountNumber: "",
                    }),
                },
                {
                    field: 'agentId',
                    operator: 'eq',
                    value: accountIdBasedOnPaymentType(queryResult?.data?.data.paymentType || 'DEPOSIT', queryResult?.data?.data || {
                        senderId: "",
                        recepientId: "",
                    }),
                },
                {
                    field: 'createdAtFrom',
                    operator: 'eq',
                    value: queryResult?.data?.data.createdAt,
                }
            ]
        }
    })

    return (
        <>
            <div className="flex justify-between p-2 gap-4">
                <div className="">
                    {["ACCEPTED"].includes(queryResult?.data?.data?.status || "UNKNOWN") && (
                        <Button className="bg-blue-500" onClick={() => {
                            mutate({
                                resource: "transactions",
                                id: queryResult?.data?.data?.transactionId || "",
                                values: {},
                                metaData: {
                                    operation: 'refundTransaction',
                                }
                            })

                        }} color="blue" >
                            Refund
                        </Button>)}
                </div>
                <div className="flex justify-end gap-4">

                    {!["SUCCESS", "FAILED", "REVERTED", "REFUNDED"].includes(queryResult?.data?.data?.status || "UNKNOWN") ? ([
                        <Button className="bg-red-500" onClick={() => {
                            mutate({
                                resource: "transactions",
                                id: queryResult?.data?.data?.transactionId || "",
                                values: {},
                                metaData: {
                                    operation: 'revertTransaction',
                                }
                            })

                        }} color="red" >
                            Revert
                        </Button>,
                        <Button
                            className="bg-green-500"
                            onClick={() => {
                                mutate({
                                    resource: "transactions",
                                    id: queryResult?.data?.data?.transactionId || "",
                                    values: {},
                                    metaData: {
                                        operation: 'acceptTransaction',
                                    }
                                })
                            }}
                            color="green">
                            Accept
                        </Button>
                    ]) : []}

                </div>
            </div>
            <Edit saveButtonProps={saveButtonProps}>

                <form>
                    <TextField label="transactionId" value={queryResult?.data?.data?.transactionId} />



                    <TextField label="paymentType" value={queryResult?.data?.data?.paymentType} />


                    <TextField label="status" value={queryResult?.data?.data?.status} />

                    <TextField label="currencyId" value={queryResult?.data?.data?.currencyId} />

                    <TextField label="senderId" value={queryResult?.data?.data?.senderId} />

                    <TextField label="recepientId" value={queryResult?.data?.data?.recepientId} />

                    <TextField label="invoiceId" value={queryResult?.data?.data?.metadata?.thirdParty?.invoiceId} />
                    
                    <TextField label="ussdCode" value={queryResult?.data?.data?.metadata?.thirdParty?.ussdCode} />

                    <TextInput
                        mt={8}
                        label="amount"
                        placeholder="amount"
                        type="number"
                        defaultValue={
                            queryResult?.data?.data?.amount
                        }
                        {...getInputProps("amount")}
                    />
                    <TextInput
                        mt={8}
                        label="senderAccountNumber"
                        placeholder="senderAccountNumber"
                        defaultValue={queryResult?.data?.data?.metadata?.thirdParty?.senderAccountNumber}
                        {...getInputProps("metadata.thirdParty.senderAccountNumber")}
                    />

                    <TextInput
                        mt={8}
                        label="senderAccountName"
                        placeholder="senderAccountName"
                        defaultValue={queryResult?.data?.data?.metadata?.thirdParty?.senderAccountName}
                        {...getInputProps("metadata.thirdParty.senderAccountName")}
                    />


                    <TextInput

                        mt={8}
                        label="receiverAccountNumber"
                        placeholder="receiverAccountNumber"
                        defaultValue={queryResult?.data?.data?.metadata?.thirdParty?.receiverAccountNumber}
                        {...getInputProps("metadata.thirdParty.receiverAccountNumber")}
                    />

                    {getInputProps("metadata.thirdParty.receiverAccountName") &&
                        <TextInput
                            mt={8}
                            label="receiverAccountName"
                            placeholder="receiverAccountName"
                            defaultValue={queryResult?.data?.data?.metadata?.thirdParty?.receiverAccountName}
                            {...getInputProps("metadata.thirdParty.receiverAccountName")}
                        />
                    }

                    <TextInput
                        mt={8}
                        label="transactionId"
                        placeholder="transactionId"
                        defaultValue={queryResult?.data?.data?.metadata?.thirdParty?.transactionId}
                        {...getInputProps("metadata.thirdParty.transactionId")}
                    />
                    {
                        getInputProps("metadata.thirdParty.isAutomated") && (
                            <Select
                                mt={12}
                                defaultValue={toString(queryResult?.data?.data?.metadata?.thirdParty?.isAutomated)}
                                {...getInputProps("metadata.thirdParty.isAutomated")}
                                value={toString(getInputProps("metadata.thirdParty.isAutomated").value)}
                                onChange={(e) => {
                                    getInputProps("metadata.thirdParty.isAutomated").onChange(e === "true")
                                }}
                                data={[{
                                    label: "true",
                                    value: "true"
                                }, {
                                    label: "false",
                                    value: "false"
                                }]}
                            />
                        )
                    }

                    {
                        getInputProps("metadata.thirdParty.automationStatus") && (
                            <Select
                                mt={12}
                                defaultValue={toString(queryResult?.data?.data?.metadata?.thirdParty?.automationStatus)}
                                {...getInputProps("metadata.thirdParty.automationStatus")}
                                value={toString(getInputProps("metadata.thirdParty.automationStatus").value)}
                                onChange={(e) => {
                                    getInputProps("metadata.thirdParty.automationStatus").onChange(e)
                                }}
                                data={[{
                                    label: "IS AUTOMATED",
                                    value: "IS_AUTOMATED"
                                }, {
                                    label: "NOT AUTOMATED",
                                    value: "NOT_AUTOMATED"
                                }]}
                            />
                        )
                    }
                </form>

                <Container size={"xl"}>
                    <p className="p-2">Date Created: {new Date(queryResult?.data?.data.createdAt || 0).toLocaleString()}</p>
                    {
                        data?.data?.map((item) => {
                            return (
                                <Card className={
                                    cn({
                                        "bg-green-400": item.resolved,
                                        "bg-red-400": !item.resolved,
                                    }, "text-white")
                                } withBorder m={"md"}>
                                    <p>{item.transactionCode}</p>
                                    <p>{item.raw}</p>
                                    <p>{new Date(item.createdAt).toLocaleString()}</p>
                                </Card>
                            )
                        })
                    }
                    <Center>
                        <Pagination onChange={setPage} total={10} defaultValue={1} />
                    </Center>
                </Container>

            </Edit>
        </>
    );
}