import { useList } from "@pankod/refine-core";
import { AgentThirdPartyWallet } from "./agentThirdPartyWallet";
import { Title } from "@pankod/refine-mantine";



export const AgentThirdPartyWallets = ({id}: {id:string}) => {

    const { data: agentThirdPartyWallets, isLoading } = useList<AgentThirdPartyWallet>({
        resource: "agentThirdPartyWallets",

        config: {
        
            hasPagination: false,
            filters: [{
                field: "agentId",
                operator: "eq",
                value: id
            }]

        },
        metaData: {
            fields: ["providerName", "accountNumber", "status","id"],
        },
    },);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {agentThirdPartyWallets?.data?.map((agentThirdPartyWallet, index) => (
                <div className="space-y-2">
                    <Title my={15}>Agent ThirdParty Wallet {agentThirdPartyWallet.providerName}</Title>
                    <AgentThirdPartyWallet
                        key={index}
                        data={agentThirdPartyWallet}
                        id={agentThirdPartyWallet.id}
                    />
                </div>
            ))}
        </div>
    )


}