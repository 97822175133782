import { useShow } from "@pankod/refine-core";
import { Text, Title, Show } from "@pankod/refine-mantine";
import { InternetPackage } from "./list";

export const InternetPackageShow: React.FC = () => {
  const { queryResult } = useShow<InternetPackage>({
    metaData: {
      fields: [
        "id",
        "name",
        "price",
        "type",
        "currencyId",
        "providerName",
        "ussdCode",
      ],
    },
  });
  const { data, isLoading, isError, error } = queryResult;

  const record = data?.data;

  if (isError) {
    console.log(error);
    return <div>Error:{error as string} </div>;
  }
  return (
    <Show isLoading={isLoading}>
      <Title order={5}>Id</Title>
      <Text mt="sm">{record?.id}</Text>

      <Title mt="sm" order={5}>
        Name
      </Title>
      <Text mt="sm">{record?.name}</Text>

      <Title mt="sm" order={5}>
        Price
      </Title>
      <Text mt="sm">{record?.price} {record?.currencyId}</Text>

      <Title mt="sm" order={5}>
        Type
      </Title>
      <Text mt="sm">{record?.type}</Text>

      <Title mt="sm" order={5}>
        Currency
      </Title>
      <Text mt="sm">{record?.currencyId}</Text>

      <Title mt="sm" order={5}>
        ProviderName
      </Title>
      <Text mt="sm">{record?.providerName}</Text>

      <Title mt="sm" order={5}>
        Ussd Code
      </Title>
      <Text mt="sm">{record?.ussdCode}</Text>
    </Show>
  );
};
