import { useNavigation, useNotification } from "@pankod/refine-core";
import { Edit, Select, TextInput } from "@pankod/refine-mantine";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { UPDATE_ACCOUNT } from "graphql/accounts/update-account.mutation";
import { GET_ACCOUNT } from "graphql/accounts/get-account.query";
import { useParams } from "@pankod/refine-react-router-v6";
import { IAccount } from "./list";

export enum AccountType {
  PERSONAL,
  BUSINESS,
  AGENT,
  CASHIER,
}

export enum Status {
  ACTIVE,
  INACTIVE,
}

interface AccountUpdateInput
  extends Omit<IAccount, "accountId" | "createdAt" | "phoneNumber"> {}

export const accountTypes: string[] = Object.keys(AccountType).filter((key) =>
  isNaN(Number(key))
);

export const AccountStatuses: string[] = Object.keys(Status).filter((key) =>
  isNaN(Number(key))
);

export const AccountEdit: React.FC = () => {
  const { id } = useParams();
  const { push } = useNavigation();
  const { open } = useNotification();

  const [inputs, setInputs] = useState<AccountUpdateInput>({
    accountType: "",
    name: "",
    status: "",
  });

  const { data } = useQuery(GET_ACCOUNT, {
    variables: { accountId: id },
  });

  const [updateAccount, { loading }] = useMutation(UPDATE_ACCOUNT, {
    refetchQueries: [
      { query: GET_ACCOUNT, variables: { accountId: id } },
      "account",
    ],
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const noneIsEmpty = Object.values(inputs).every((value) => value !== "");

    try {
      if (noneIsEmpty) {
        await updateAccount({
          variables: {
            input: {
              data: { ...inputs },
              where: { accountId: id },
            },
          },
        });
        open?.({
          type: "success",
          message: "Successfully updated account",
        });
        push("/accounts");
      } else
        open?.({
          type: "error",
          message: "Make sure all fields have data",
        });
    } catch (e: any) {
      open?.({
        type: "error",
        message: e.message,
      });
    }
  };

  useEffect(() => {
    if (data) {
      const {
        __typename,
        accountId,
        phoneNumber,
        wallets,
        createdAt,
        ...editableData
      } = data.account;
      setInputs(editableData);
    }
  }, [data]);

  return (
    <Edit isLoading={loading} saveButtonProps={{ onClick: handleSubmit }}>
      <form>
        <Select
          mt={8}
          label="Type"
          placeholder="Select a type"
          value={inputs.accountType}
          onChange={(e: string) => setInputs({ ...inputs, accountType: e })}
          data={accountTypes}
        />

        <TextInput
          mt={8}
          label="Name"
          placeholder="name"
          value={inputs.name}
          onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
        />

        <Select
          mt={8}
          label="Status"
          placeholder="Select a type"
          value={inputs.status}
          data={AccountStatuses}
          onChange={(e: string) => setInputs({ ...inputs, status: e })}
        />
      </form>
    </Edit>
  );
};
