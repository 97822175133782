import { Flex } from "@mantine/core"
import { Select, Text, TextInput } from "@pankod/refine-mantine"

type AgentPermissionProps = {
    permissions: string
    setPermissions: (permissions: string) => void
    name: string
}
export const AgentPermission: React.FC<AgentPermissionProps> = (
    { permissions, setPermissions, name }: AgentPermissionProps
) => {
    return (
        <Flex
            // mih={50}
            gap="md"
            my={30}
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
            maw={500}
        >
            <Text>
                {name}
            </Text>
            <TextInput
                placeholder="seperate with comma(,)"
                // label="agentNumber"
                // size="xs"
                w={300}
                value={permissions.toLocaleLowerCase()}

                onChange={(e) => {
                    const value = e.currentTarget.value
                    setPermissions(value.toUpperCase())
                }}
            />
        </Flex>
    )
}