import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CustomTable } from "components/CustomTable";

export type IAgentAccount = {
  id: string;
  accountId: string;
  name: string;
  phoneNumber: string;
  status: string;
};
const agentAccountFields: IAgentAccount = {
  id: "",
  accountId: "",
  name: "",
  phoneNumber: "",
  status: "",
};
const agentAccountFieldKeys = Object.keys(agentAccountFields).map((key) => {
  return key;
});
export const AgentAccountList: React.FC<IResourceComponentsProps> = () => {
  return <CustomTable fields={agentAccountFieldKeys} />
};
