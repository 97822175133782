import { gql } from "@apollo/client";

export const GET_EXCHANGE_RATES = gql`
  query exchangeRates(
    $where: JSON
    $sortOrder: String
    $start: Int
    $limit: Int
  ) {
    exchangeRates(
      where: $where
      sortOrder: $sortOrder
      start: $start
      limit: $limit
    ) {
      id
      fromCurrencyId
      toCurrencyId
      rate
      createdAt
    }
  }
`;
