import React, { useState } from "react";
import { TextInput, useDebouncedState } from "@pankod/refine-mantine";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Select, SelectItem } from "@tremor/react";
import { NewCustomTable } from "components/NewCustomTable";
import { GET_ACCOUNTS } from "graphql/accounts/get-accounts.query";

export type IAccount = {
  accountId: string;
  accountType: string;
  phoneNumber: string;
  name: string;
  status: string;
  createdAt: string;
};

export const AccountFields: IAccount = {
  accountId: "",
  accountType: "",
  phoneNumber: "",
  name: "",
  status: "",
  createdAt: new Date().toISOString(),
};

const accountFiledKeys = Object.keys(AccountFields).map((key) => {
  return key;
});

export const AccountList: React.FC<IResourceComponentsProps> = () => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [search, setSearch] = useDebouncedState<string| undefined>(undefined, 400);

  return (
    <NewCustomTable
      identifier="accountId"
      fields={accountFiledKeys}
      name="accounts"
      query={GET_ACCOUNTS}
      variables={{
        start: 0,
        limit: 10,
        sortOrder,
      }}
      filters={{
        sortOrder,
        where: { search },
      }}
    >
      <>
        <TextInput
          className="mb-2"
          type="text"
          placeholder="search account using phone-number or accountId"
          onChange={(e) =>
            setSearch(
              e.currentTarget.value ? e.currentTarget.value.trim() : undefined
            )
          }
        />

        <Select
          className="w-fit mx-auto"
          value={sortOrder}
          onValueChange={setSortOrder as any}
          placeholder="Sort"
        >
          <SelectItem value="asc">Asc</SelectItem>
          <SelectItem value="desc">Desc</SelectItem>
        </Select>
      </>
    </NewCustomTable>
  );
};
