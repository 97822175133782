import React, { useEffect } from "react";
import {
  ScrollArea,
  List,
  Table,
  EditButton,
  Group,
  ShowButton,
} from "@pankod/refine-mantine";
import { DocumentNode, useQuery } from "@apollo/client";

export const NewCustomTable: React.FC<{
  identifier?: string;
  fields: string[];
  name: string;
  query: DocumentNode;
  variables?: Record<string, any>;
  filters?: {
    where?: Object;
    sortBy?: string;
    sortOrder?: "asc" | "desc";
    dateStart?: string;
    dateEnd?: string;
  };
  customButtons?: (data: any) => React.ReactNode[];
  children?: React.ReactNode;
}> = ({
  fields,
  name,
  query,
  variables,
  identifier = "id",
  filters,
  customButtons,
  children,
}) => {
  const { data, refetch } = useQuery(query, {
    variables,
  });

  useEffect(() => {
    refetch({ ...filters });
  }, [filters, refetch]);

  return (
    <List canCreate>
      {children}

      <ScrollArea>
        <Table verticalSpacing={"md"} highlightOnHover>
          <thead>
            <tr>
              {fields.map((key: string) => (
                <th>{key}</th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.[name].map((row: any) => {
              return (
                <tr key={row.id}>
                  {fields.map((key) => {
                    if (key.includes("expiry") || key.includes("createdAt")) {
                      return <td>{new Date(row[key]).toLocaleString()}</td>;
                    }

                    if (key.includes("balance") || key.includes("amount")) {
                      return <td>{row[key] / 100}</td>;
                    }
                    return <td>{row[key]}</td>;
                  })}
                  <Group spacing="md" noWrap>
                    <ShowButton hideText recordItemId={row[identifier]} />
                    <EditButton hideText recordItemId={row[identifier]} />
                    {customButtons &&
                      customButtons(row)?.map((button) => {
                        return button;
                      })}
                  </Group>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ScrollArea>
    </List>
  );
};
