import { HttpError } from "@pankod/refine-core";
import { Breadcrumb, Edit, Select, Text, TextInput, Title, useForm } from "@pankod/refine-mantine"
import { isEmpty, omit } from "lodash";
import { toString } from "lodash";

import { IProviderPreference } from "./list";
const TextField: React.FC<{ label: string; value?: string }> = ({
    label,
    value,
}) => (
    <><Title order={5}>{label}</Title><Text mt="sm">{value}</Text></>
)
export const ProviderPreferenceEdit = () => {

    const {
        saveButtonProps,
        getInputProps,
        refineCore: { queryResult, formLoading },
    } = useForm<IProviderPreference, HttpError>({
        initialValues: {
            verificationStatus: "INCOMPLETE",
            fullName: "",
            providerNumber: "",

        },
        refineCoreProps: {
            queryOptions: {
                retry: 3,
            },
            metaData: {
                fields: [
                    "id",
                    "accountId",
                    "providerName",
                    "fullName",
                    "providerNumber",
                    "verificationStatus",
                ],
            },
        },

        validate: {},
        transformValues(values: any) {
            omit(values, ["id", "accountId", "providerName"])
            if (isEmpty(values.fullName)) {
                omit(values, ["fullName"])
            }
            if (isEmpty(values.providerNumber)) {
                omit(values, ["providerNumber"])
            }
            return {
                ...values,
            };
        },
    });




    return (
        <Edit isLoading={formLoading} breadcrumb={<Breadcrumb />} saveButtonProps={saveButtonProps}>
            <TextField label="AccountId" value={queryResult?.data?.data.accountId} />
            <TextField label="ProviderName" value={queryResult?.data?.data.providerName} />
            <form >
                <TextInput
                    mt={8}
                    label="providerNumber"
                    placeholder="providerNumber"
                    type="string"
                    defaultValue={
                        queryResult?.data?.data?.providerNumber
                    }
                    {...getInputProps("providerNumber")}
                />
                <TextInput
                    mt={8}
                    label="fullName"
                    placeholder="fullName"
                    type="string"
                    defaultValue={
                        queryResult?.data?.data?.fullName
                    }
                    {...getInputProps("fullName")}
                />

                <Select
                    mt={12}
                    defaultValue={toString(queryResult?.data?.data?.verificationStatus)}
                    {...getInputProps("verificationStatus")}
                    value={toString(getInputProps("verificationStatus").value)}
                    onChange={(e) => {
                        getInputProps("verificationStatus").onChange(e)
                    }}
                    data={[{
                        label: "VERIFIED",
                        value: "VERIFIED"
                    },
                    {
                        label: "UNVERIFIED",
                        value: "UNVERIFIED"
                    },
                    {
                        label: "PENDING",
                        value: "PENDING"
                    },
                    {
                        label: "INCOMPLETE",
                        value: "INCOMPLETE"
                    },
                    ]}
                />

            </form>
        </Edit>
    )
}