import { useList, useQuery, useUpdate } from "@pankod/refine-core"
import { Card, Center, Container, Pagination, TextInput, usePagination, } from "@pankod/refine-mantine"
import { DateRangePicker, DateRangePickerItem, Select, SelectItem } from "@tremor/react";
// import { DateRangePicker } from "@tremor/react";
import { useState } from "react"
import cn from "classnames";

export const SmsList: React.FC = () => {

    const { active, setPage } = usePagination({ total: 10, initialPage: 1 });
    const [agentId, setAgentId] = useState('')
    const [status, setStatus] = useState<'ALL' | 'RESOLVED' | 'UNRESOLVED'>('ALL')
    const [order, setOrder] = useState<'asc' | 'desc'>('desc')
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionCode, setTransactionCode] = useState('')
    const { mutate } = useUpdate();

    const [createdAt, setCreatedAt] = useState({
        gte: '',
        lte: ''
    })

    const { data, isLoading, } = useList<{
        transactionCode: string;
        raw: string;
        createdAt: string;
        resolved: boolean;
    }>({
        resource: "sms",

        metaData: {
            fields: ["transactionCode", 'resolved', "raw", "createdAt"],
            operation: 'sms',
        },
        config: {
            pagination: {
                current: active,
                pageSize: 10,
            },
            filters: [
                {
                    field: 'status',
                    operator: 'eq',
                    value: status,

                },
                {
                    field: 'order',
                    operator: 'eq',
                    value: order,
                },
                {
                    field: 'transactionCode',
                    operator: 'eq',
                    value: transactionCode,

                }, {
                    field: 'amount',
                    operator: 'eq',
                    value: amount,
                },
                {
                    field: 'phoneNumber',
                    operator: 'eq',
                    value: phoneNumber,
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: name,
                },
                {
                    field: 'agentId',
                    operator: 'eq',
                    value: agentId,
                },

                {
                    field: 'createdAtFrom',
                    operator: 'eq',
                    value: createdAt.gte,
                },
                {
                    field: 'createdAtTo',
                    operator: 'eq',
                    value: createdAt.lte,
                }
            ]
        }
    })



    const sms = data?.data ?? []
    if (isLoading) {
        return <div>loading...</div>
    }

    return (
        <>
            <Container>
                <div className="flex flex-row gap-3 flex-wrap  justify-center items-center">
                    <TextInput
                        label="transactionCode"
                        size="sm"
                        onBlur={
                            (e) => {
                                setTransactionCode(e.currentTarget.value)

                            }
                        } placeholder="search transactionId" />
                    <TextInput
                        label="agentId"
                        onBlur={
                            (e) => {
                                setAgentId(e.currentTarget.value)
                            }
                        } placeholder="search transactionId" />
                    <TextInput
                        label="phoneNumber"
                        onBlur={
                            (e) => {
                                setPhoneNumber(e.currentTarget.value)
                            }
                        } placeholder="search transactionId" />
                    <TextInput
                        label="amount"
                        onBlur={
                            (e) => {
                                setAmount(e.currentTarget.value)

                            }
                        } placeholder="search transactionId" />

                    <TextInput
                        label="name"
                        onBlur={
                            (e) => {
                                setName(e.currentTarget.value)

                            }
                        } placeholder="name" />

                    {/* <div className="max-w-sm mx-auto  space-y-6"> */}
                    <Select className="w-12 " value={order} onValueChange={setOrder as any} placeholder="Sort">
                        <SelectItem value="asc" >
                            Asc
                        </SelectItem>
                        <SelectItem value="desc" >
                            Desc
                        </SelectItem>
                    </Select>
                    <Select className="w-12 " value={status} onValueChange={setStatus as any} placeholder="Status">
                        <SelectItem value="ALL" >
                            ALL
                        </SelectItem>
                        <SelectItem value="RESOLVED" >
                            RESOLVED
                        </SelectItem>
                        <SelectItem value="UNRESOLVED" >
                            UNRESOLVED
                        </SelectItem>
                    </Select>

                    {/* </div> */}
                </div>

                <div className="flex flex-row justify-center gap-4 my-4" >

                    <DateRangePicker
                        placeholder="DateFrom - DateTo" onValueChange={({ from, to }) => {
                            if (from)
                                setCreatedAt({
                                    gte: from.toISOString(),
                                    lte: to?.toISOString() ?? ''
                                });
                            if (to)
                                setCreatedAt({
                                    gte: from?.toISOString() ?? '',
                                    lte: to.toISOString()
                                });
                        }} className="max-w-sm mx-auto" enableSelect={false} >
                    </DateRangePicker>
                </div>
                <Container>
                    {sms.map((item) => {
                        return (
                            <Card

                                className={
                                    cn({
                                        "bg-green-400": item.resolved === true,
                                        "bg-red-400": item.resolved === false,
                                    }, "text-white")
                                } withBorder m={"md"}>

                                {!item.resolved && (<div className="flex flex-row justify-between">

                                    <p>{item.transactionCode}</p>
                                    <button onClick={() => {
                                        mutate({
                                            resource: "sms",
                                            id: item.transactionCode,
                                            values: {},
                                            metaData: {
                                                fields: ["transactionCode", 'resolved', "raw", "createdAt"],
                                                operation: 'refundUnresolvedSms',
                                            }
                                        },)
                                    }} className=" rounded-md px-2 border-green-300 border hover:bg-green-300 ">Refund</button>

                                </div>)}
                                <div>
                                    <p>{item.raw}</p>
                                    <p>{new Date(item.createdAt).toLocaleString()}</p>
                                </div>
                            </Card>
                        )
                    })}
                </Container>
                <Center>
                    <Pagination onChange={setPage} total={10} defaultValue={1} />
                </Center>

            </Container>
        </>
    )
}