import { useShow } from "@pankod/refine-core";
import { Text, Title, Show } from "@pankod/refine-mantine";
import { ITransaction } from "./list";
export type ITransactions = ITransaction & {
    sender: {
        phoneNumber: string;
        name: string;
        accountId: string;
    },
    recepient?: {
        phoneNumber: string;
        name: string
        accountId: string;
    },
    currency: {
        code: string;
    },

    currencyId: string;
    paymentType: string;
    timeTaken: number;
    metadata?: {
        thirdParty?: {
            provider: string;
            transactionId: string;
            receiverAccountNumber: string;
            senderAccountNumber: string;
        }
    }
};

export const TransactionsShow: React.FC = () => {
    const { queryResult } = useShow<ITransactions>({
        metaData: {
            fields: [
                "transactionId",
                "amount",
                "status",
                "paymentType",
                "timeTaken",
                {
                    metadata: [{
                        thirdParty: ["provider", "transactionId", "receiverAccountNumber", "senderAccountNumber"]
                    }]
                },
                {
                    sender: ["name", "accountId", "phoneNumber"],
                    recepient: ["name", "accountId", "phoneNumber"],
                    currency: ["code"]
                },
                "createdAt"
            ],
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;
    

    return (
        <Show isLoading={isLoading}>
            <Title order={5}>Id</Title>
            <Text mt="sm">{record?.transactionId}</Text>

            <Title mt="sm" order={5}>
                Amount
            </Title>
            <Text mt="sm">{Number(record?.amount)/100} {record?.currency.code}</Text>

            <Title mt="sm" order={5}>
                Sender Name
            </Title>
            <Text>
                {record?.sender.name}
            </Text>

            <Title mt="sm" order={5}>
                Sender Account Id
            </Title>
            <Text>
                {record?.sender.accountId}
            </Text>
            <Title mt="sm" order={5}>
                Sender Phone Number
            </Title>
            <Text>
                {record?.sender.phoneNumber}
            </Text>
            <Title mt="sm" order={5}>
                Recipient Name
            </Title>
            <Text>
                {record?.recepient?.name}
            </Text>

            <Title mt="sm" order={5}>
                Recipient Account Id
            </Title>
            <Text>
                {record?.recepient?.accountId}
            </Text>
            <Title mt="sm" order={5}>
                Recipient Phone Number
            </Title>
            <Text>
                {record?.recepient?.phoneNumber}
            </Text>

            <Title mt="sm" order={5}>
                Transaction Status 
            </Title>
            <Text mt="sm">{record?.status}</Text>

            <Title mt="sm" order={5}>
                Payment Type
            </Title>
            <Text mt="sm">{record?.paymentType}</Text>


            <Title mt="sm" order={5}>
                Provider
            </Title>
            <Text mt="sm">{record?.metadata?.thirdParty?.provider}</Text>

            <Title mt="sm" order={5}>
                Third Party Id
            </Title>
            <Text mt="sm">{record?.metadata?.thirdParty?.transactionId}</Text>

            <Title mt="sm" order={5}>
                Third party Receiver Account Number
            </Title>
            <Text mt="sm">{record?.metadata?.thirdParty?.receiverAccountNumber}</Text>

            <Title mt="sm" order={5}>
                Third party Sender Account Number
            </Title>
            <Text mt="sm">{record?.metadata?.thirdParty?.senderAccountNumber}</Text>

            <Title mt="sm" order={5}>
                Created At
            </Title>
            <Text mt="sm">{new Date(record?.createdAt!).toDateString()}</Text>
        </Show>
    );
};
