import { TextInput, usePagination, } from "@pankod/refine-mantine"
// import { DateRangePicker } from "@tremor/react";
import { useState } from "react"
import { CustomTable } from "components/CustomTable";
export type IProviderPreference = {
    id: string;

    accountId: string;

    providerName: string;

    providerNumber: string;

    verificationStatus: string;

    fullName: string;
};
const providerPreferenceField: IProviderPreference = {
    id: "",
    accountId: "",
    providerName: "",
    providerNumber: "",
    verificationStatus: "",
    fullName: "",
};
const providerPreferenceFieldKeys = Object.keys(providerPreferenceField).map((key) => {
    return key;
});


export const ProviderPreferenceList: React.FC = () => {

    return <>
        <CustomTable
            initialFilter={[

            ]}
            identifier="id" fields={providerPreferenceFieldKeys}

        >{({ setFilters }) => (
            <>

                <TextInput
                    onBlur={(e) => {
                        setFilters([{
                            field: 'search',
                            operator: 'eq',
                            value: e.currentTarget.value
                        }]);
                    }} placeholder="search providerPreference" />
            </>
        )}</CustomTable>

    </>
}