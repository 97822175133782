import { Refine } from "@pankod/refine-core";
import {
  NotificationsProvider,
  notificationProvider,
  MantineProvider,
  Global,
  ReadyPage,
  ErrorComponent,
  useLocalStorage,
  ColorSchemeProvider,
  ColorScheme,
  DarkTheme,
  AuthPage,
} from "@pankod/refine-mantine";

import dataProvider, { GraphQLClient } from "@pankod/refine-graphql";
import routerProvider from "@pankod/refine-react-router-v6";
import { Title, Sider, Layout, Header } from "components/layout";

import { InternetPackageShow } from "pages/internetPackages/show";
import { InternetPackageList } from "pages/internetPackages/list";
import { InternetPackageCreate } from "pages/internetPackages/create";
import { InternetPackageEdit } from "pages/internetPackages/edit";
import { authProvider, TOKEN_KEY } from "authProvider";
import { AgentAccountList } from "pages/agents/list";
import { AgentAccountCreate } from "pages/agents/create";
import { AgentAccountEdit } from "pages/agents/edit";
import { AgentShow } from "pages/agents/show";
import { AccountList } from "pages/accounts/list";
import { AccountShow } from "pages/accounts/show";
import { AccountEdit } from "pages/accounts/edit";
import { ExchangeRateList } from "pages/exchange-rates/list";
import { ExchangeRateShow } from "pages/exchange-rates/show";
import { ExchangeRateEdit } from "pages/exchange-rates/edit";
import { ExchangeRateCreate } from "pages/exchange-rates/create";
import { WalletList } from "pages/wallets/list";
import { TransactionsShow } from "pages/transactions/show";
import { TransactionList } from "pages/transactions/list";
import { TransactionEdit } from "pages/transactions/edit";
import { SmsList } from "pages/sms/list";
import { ProviderPreferenceList } from "pages/providerPrefernece/list";
import { ProviderPreferenceEdit } from "pages/providerPrefernece/edit";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const API_URL =
  process.env.REACT_APP_API_URL ?? "http://localhost:8000/graphql";

const client = new GraphQLClient(API_URL, {
  headers: {
    authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
  },
});
const gqlDataProvider = dataProvider(client);

const apolloClient = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
  headers: {
    authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
  },
});

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
      <ApolloProvider client={apolloClient}>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider theme={DarkTheme} withNormalizeCSS withGlobalStyles>
            <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
            <NotificationsProvider position="top-right">
              <Refine
                options={{
                  disableTelemetry: true,
                }}
                dataProvider={gqlDataProvider}
                notificationProvider={notificationProvider}
                ReadyPage={ReadyPage}
                catchAll={<ErrorComponent />}
                Title={Title}
                Sider={Sider}
                Layout={Layout}
                Header={Header}
                routerProvider={routerProvider}
                authProvider={authProvider}
                // DashboardPage={Dashboard}
                resources={[
                  {
                    name: "transactions",
                    list: TransactionList,
                    show: TransactionsShow,
                    edit: TransactionEdit,
                  },

                  {
                    name: "adminInternetPackages",
                    list: InternetPackageList,
                    show: InternetPackageShow,
                    create: InternetPackageCreate,
                    edit: InternetPackageEdit,
                  },

                  {
                    name: "accounts",
                    list: AccountList,
                    show: AccountShow,
                    edit: AccountEdit,
                  },

                  {
                    name: "exchangeRates",
                    list: ExchangeRateList,
                    show: ExchangeRateShow,
                    edit: ExchangeRateEdit,
                    create: ExchangeRateCreate,
                  },
                  {
                    name: "sms",
                    list: SmsList,
                  },
                  {
                    name: "agentAccounts",
                    list: AgentAccountList,
                    create: AgentAccountCreate,
                    edit: AgentAccountEdit,
                    show: AgentShow,
                    parentName: "account",
                    options: {
                      route: "agents",
                    },
                  },
                  {
                    name: "providerPreferences",
                    list: ProviderPreferenceList,
                    edit: ProviderPreferenceEdit,
                  },
                  {
                    name: "wallets",
                    list: WalletList,
                    options: {
                      route: "wallets",
                    },
                  },
                ]}
                LoginPage={() => (
                  <AuthPage
                    formProps={{
                      validate: {},
                    }}
                  />
                )}
              ></Refine>
            </NotificationsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </ApolloProvider>
  );
}

export default App;
