import React, { useState } from "react";
import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";
import { CustomTable } from "components/CustomTable";
import { Button, TextInput, } from "@pankod/refine-mantine";
import { Select, SelectItem } from "@tremor/react";

export type ICreateTransaction = ITransaction;
export type ITransaction = {
    transactionId: string;
    amount: number;
    status: string;
    currencyId: string;
    paymentType: string;
    createdAt: string;
    blockedBy: string;
};
const transactionFields: ITransaction = {
    transactionId: "",
    amount: 0,
    status: "",
    currencyId: "",
    paymentType: "",
    blockedBy: "",
    createdAt: "",

};
const transactionFieldKeys = Object.keys(transactionFields).map((key) => {
    return key;
});



export const TransactionList: React.FC<IResourceComponentsProps> = () => {
    const { mutate } = useUpdate();
    const [status, setStatus] = useState<'ALL' | 'ACCEPTED' | 'PENDING' | 'REVERTED' | 'SUCCESS' | 'BLOCKED' | 'FAILED' | 'REFUNDED'>('ALL')
    const [currency, setCurrency] = useState<'ALL' | 'USD' | 'SLSH'>('ALL')
    const [nameCheckingStatus,setNameCheckingStatus]  = useState<'ALL' | 'CHECKED' | 'UNCHECKED'>('ALL')
    const [automationStatus, setAutomationStatus] = useState<'ALL' | 'AUTOMATED' | 'MANUAL'>('ALL')

    const [paymentType, setPaymentType] = useState<'ALL' | 'WITHDRAWAL' | 'DEPOSIT' | 'INTERNET_TOPUP' | 'TRANSFER'>('ALL')
    return <>
        <CustomTable
            initialFilter={[
                {

                    field: 'status', operator: 'eq', value: status,
                }, {
                    field: 'automationStatus', operator: 'eq', value: automationStatus
                },
                {
                    field: 'paymentType', operator: 'eq', value: paymentType
                },
                {
                    field: 'nameCheckStatus', operator: 'eq', value: nameCheckingStatus
                },
                {
                    field: 'currency', operator: 'eq', value: currency
                }
            ]}
            identifier="transactionId" fields={transactionFieldKeys}
            customButtons={({ id, status, transactionId }) =>
                !["SUCCESS", "FAILED", "REVERTED", "BLOCKED", "REFUNDED"].includes(status) ? ([
                    <Button onClick={() => {
                        mutate({
                            resource: "transactions",
                            id: transactionId,
                            values: {},
                            metaData: {
                                operation: 'revertTransaction',
                            }
                        })

                    }} color="red" >
                        Revert
                    </Button>,
                    ((status !== "BLOCKED") && <Button
                        onClick={() => {
                            mutate({
                                resource: "transactions",
                                id: transactionId,
                                values: {},
                                metaData: {
                                    operation: 'acceptTransaction',
                                }
                            })
                        }}
                        color="green">
                        Accept
                    </Button>)
                ]) : []
            }
        >{({ setFilters }) => (
            <>
                <div className="flex justify-between pb-3">
                <Select className="w-12 " value={currency} onValueChange={setCurrency as any} placeholder="currency">
                        <SelectItem value="ALL">
                            CURRENCY
                        </SelectItem>
                        <SelectItem value="SLSH">
                            SLSH
                        </SelectItem>
                        <SelectItem value="USD">
                            USD
                        </SelectItem>
                    </Select>
                    <Select className="w-12 " value={status} onValueChange={setStatus as any} placeholder="Status">
                        <SelectItem value="ALL">
                            STATUS
                        </SelectItem>
                        <SelectItem value="ACCEPTED">
                            ACCEPTED
                        </SelectItem>
                        <SelectItem value="PENDING">
                            PENDING
                        </SelectItem>
                        <SelectItem value="REVERTED">
                            REVERTED
                        </SelectItem>
                        <SelectItem value="FAILED">
                            FAILED
                        </SelectItem>
                        <SelectItem value="BLOCKED">
                            BLOCKED
                        </SelectItem>
                        <SelectItem value="SUCCESS">
                            SUCCESS
                        </SelectItem>
                        <SelectItem value="REFUNDED">
                            REFUNDED
                        </SelectItem>
                    </Select>
                    <Select className="w-12 " value={paymentType} onValueChange={setPaymentType as any} placeholder="ALL">
                        <SelectItem value="ALL">
                            PAYMENT
                        </SelectItem>
                        <SelectItem value="WITHDRAWAL">
                            WITHDRAWAL
                        </SelectItem>
                        <SelectItem value="DEPOSIT">
                            DEPOSIT
                        </SelectItem>
                        <SelectItem value="INTERNET_TOPUP">
                            INTERNET
                        </SelectItem>
                        <SelectItem value="TRANSFER">
                            TRANSFER
                        </SelectItem>
                    </Select>
                    <Select className="w-12 " value={automationStatus} onValueChange={setAutomationStatus as any} placeholder="AutomationStatus">
                        <SelectItem value="ALL">
                            AUTOMATION
                        </SelectItem>
                        <SelectItem value="AUTOMATED">
                            AUTOMATED
                        </SelectItem>
                        <SelectItem value="MANUAL">
                            MANUAL
                        </SelectItem>
                    </Select>

                    <Select className="w-12 " value={nameCheckingStatus} onValueChange={setNameCheckingStatus as any} placeholder="NameCheckingStatus">
                        <SelectItem value="ALL">
                            NAME_CHECK
                        </SelectItem>
                        <SelectItem value="CHECKED">
                            CHECKED
                        </SelectItem>
                        <SelectItem value="UNCHECKED">
                            UNCHECKED
                        </SelectItem>
                    </Select>
                </div>

                <TextInput
                    onBlur={(e) => {
                        setFilters([{
                            field: 'search',
                            operator: 'eq',
                            value: e.currentTarget.value
                        }]);
                    }} placeholder="search transactionId" />
            </>
        )}</CustomTable>

    </>
};
