import { gql } from "@apollo/client";

export const GET_ACCOUNT = gql`
  query account($accountId: String!) {
    account(accountId: $accountId) {
      accountId
      accountType
      phoneNumber
      name
      status
      createdAt
      wallets {
        walletId
        currency {
          code
        }
        balance
      }
    }
  }
`;
