import { useUpdate } from "@pankod/refine-core";
import { Button, TextInput } from "@pankod/refine-mantine";
import { useState } from "react";

export type AgentThirdPartyWallet = {
    providerName: string;
    accountNumber: string;
    status: string;
    id:string

}
export const AgentThirdPartyWallet = (props: {
    data: Omit<AgentThirdPartyWallet,'id'>
    id: string
}) => {
    const [agentThirdPartyWallet, setAgentThirdPartyWallet] = useState(props.data)
    const { mutate } = useUpdate({
    });

    return (
        <div className="space-y-4">
            <div className="flex flex-col space-y-2 items-start"
            >
                <TextInput
                    label={"Provider Name"}
                    placeholder="0"
                    defaultValue={agentThirdPartyWallet.providerName}
                    value={agentThirdPartyWallet.providerName}
                    type="text"
                    size="lg"

                    onChange={(e) => {
                        setAgentThirdPartyWallet({
                            ...agentThirdPartyWallet,
                            providerName: e.target.value,
                        })
                    }}
                />

                <TextInput
                    label={"Account Number"}
                    placeholder="0"
                    defaultValue={agentThirdPartyWallet.accountNumber}
                    value={agentThirdPartyWallet.accountNumber}
                    size="lg"
                    type="text"
                    onChange={(e) => {
                        setAgentThirdPartyWallet({
                            ...agentThirdPartyWallet,
                            accountNumber: e.target.value,
                        })
                    }}
                />

                <TextInput
                    label={"Status"}
                    placeholder="0"
                    defaultValue={agentThirdPartyWallet.status}
                    value={agentThirdPartyWallet.status}
                    type="text"
                    size="lg"
                    onChange={(e) => {
                        setAgentThirdPartyWallet({
                            ...agentThirdPartyWallet,
                            status: e.target.value,
                        })
                    }}
                />


                <Button
                  
                    onClick={() => {
                        mutate({
                            resource: "agentThirdPartyWallets",
                            id: props.id,
                            values: agentThirdPartyWallet,
                        })
                    }
                    }
                >   Update    </Button>

            </div>
        </div>
    )
}