import {
  Create,
  NumberInput,
  Select,
  TextInput,
  useForm,
} from "@pankod/refine-mantine";
import {
  CurrencyIdType,
  InternetPackage,
  internetPackageFields,
  InternetPackageType,
  ProviderNameType,
} from "./list";
import { HttpError } from "@pankod/refine-core";

export type ICreateInternetPackage = Omit<InternetPackage, "id">;

export const packageTypes: string[] = Object.keys(InternetPackageType).filter(
  (key) => isNaN(Number(key))
);
export const currencyIdTypes: string[] = Object.keys(CurrencyIdType).filter(
  (key) => isNaN(Number(key))
);
export const providerNameTypes: string[] = Object.keys(ProviderNameType).filter(
  (key) => isNaN(Number(key))
);

export const InternetPackageCreate: React.FC = () => {
  const { values, setValues, getInputProps, saveButtonProps } = useForm<
    ICreateInternetPackage,
    HttpError,
    Record<string, any>
  >({
    initialValues: {
      ...internetPackageFields,
    },
    refineCoreProps: {
      queryOptions: {
        retry: 3,
      },
      metaData: {
        fields: [
          "id",
          "name",
          "price",
          "type",
          "currencyId",
          "providerName",
          "ussdCode",
        ],
      },
    },
    transformValues(values: any) {
      //remove agentId
      delete values.id;
      return {
        ...values,
        price:Number(values.price)
      };
    },
    validate: {},
  });
  return (
    <Create saveButtonProps={saveButtonProps}>
      <form>
        <TextInput
          mt={8}
          label="Name"
          placeholder="name"
          {...getInputProps("name")}
        />

        <TextInput
          mt={8}
          label="Price"
          placeholder="price"
          {...getInputProps("price")}
        />

        <Select
          mt={8}
          label="Type"
          placeholder="Select a type"
          {...getInputProps("type")}
          onChange={(e) => setValues({ ...values, type: e })}
          data={packageTypes}
        />

        <Select
          mt={8}
          label="Currency"
          placeholder="Select a currency"
          {...getInputProps("currencyId")}
          onChange={(e) => setValues({ ...values, currencyId: e })}
          data={currencyIdTypes}
        />

        <Select
          mt={8}
          label="Provider"
          placeholder="Select a provider"
          {...getInputProps("providerName")}
          onChange={(e) => setValues({ ...values, providerName: e })}
          data={providerNameTypes}
        />

        <TextInput
          mt={8}
          label="USSD code"
          placeholder="ussdCode"
          {...getInputProps("ussdCode")}
        />

        <button
          onClick={(e) => {
            e.preventDefault();
            console.log(values);
          }}
        >
          check
        </button>
      </form>
    </Create>
  );
};
