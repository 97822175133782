import { AuthProvider } from "@pankod/refine-core";
import axios from "axios";
export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthProvider = {
  login: async ({ accessToken, ...rest }) => {
    const url = process.env.REACT_APP_API_REST_URL ?? "http://localhost:8000";
    const result = await axios.post(`${url}/auth/login`, {
      phoneNumber: rest.email,
      otp: rest.password,
    });

    if (result.data.accessToken) {
      localStorage.setItem(TOKEN_KEY, result.data.accessToken);
      return Promise.resolve();
    }
    return Promise.reject(new Error("username: admin, password: admin"));
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve(token);
  },
};
