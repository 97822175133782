import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { GET_EXCHANGE_RATES } from "graphql/exchange-rates/get-exchange-rates.query";
import { NewCustomTable } from "components/NewCustomTable";

export enum Currency {
  EP,
  USD,
  SLSH,
  KSH,
}

export type ExchangeRate = {
  id: string;
  fromCurrencyId: Currency;
  toCurrencyId: Currency;
  rate: number;
  createdAt: string;
};

export const ExchangeRateFields: ExchangeRate = {
  id: "",
  fromCurrencyId: Currency.USD,
  toCurrencyId: Currency.SLSH,
  rate: 0,
  createdAt: new Date().toISOString(),
};

const exchangeRateFieldsKeys = Object.keys(ExchangeRateFields).map((key) => {
  return key;
});

export const ExchangeRateList: React.FC<IResourceComponentsProps> = () => {
  return (
    <NewCustomTable
      identifier="id"
      fields={exchangeRateFieldsKeys}
      name="exchangeRates"
      query={GET_EXCHANGE_RATES}
      variables={{
        start: 0,
        limit: 10,
        sortOrder: "desc",
      }}
    />
  );
};
