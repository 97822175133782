import { Text, Title, Show } from "@pankod/refine-mantine";
import { GET_ACCOUNT } from "graphql/accounts/get-account.query";
import { useParams } from "@pankod/refine-react-router-v6";
import { useQuery } from "@apollo/client";
import { IAccount } from "./list";

type Wallet = {
  walletId: string;
  currency: {
    code: string;
  };
  balance: string;
};

export type Account = IAccount & {
  wallets: Wallet[];
};

export const AccountShow: React.FC = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_ACCOUNT, {
    variables: { accountId: id },
  });

  return (
    <Show isLoading={loading}>
      <Title order={5}>Account Id</Title>
      <Text mt="sm">{data?.account.accountId}</Text>

      <Title mt="sm" order={5}>
        Account Type
      </Title>
      <Text mt="sm">{data?.account.accountType}</Text>

      <Title mt="sm" order={5}>
        Name
      </Title>
      <Text mt="sm">{data?.account.name}</Text>

      <Title mt="sm" order={5}>
        Phone Number
      </Title>
      <Text mt="sm">{data?.account.phoneNumber}</Text>

      <Title mt="sm" order={5}>
        Status
      </Title>
      <Text mt="sm">{data?.account.status}</Text>

      <Title mt="sm" order={5}>
        Wallets
      </Title>
      {data?.account.wallets?.map((wallet: Wallet) => (
        <Text mt="sm">
          {Number(wallet.balance) / 100} {wallet.currency.code}
        </Text>
      ))}
    </Show>
  );
};
