import { HttpError, useList } from "@pankod/refine-core";
import { Create, Select, TextInput, useForm } from "@pankod/refine-mantine";
import { IAgentAccount } from "./list";
export type ICreateAgent = Omit<
  IAgentAccount,
  "id" | "accountId" | "status"
> & {
  agentNumber: string;
};

const agentData: ICreateAgent = {
  phoneNumber: "",
  name: "",
  agentNumber: "",
};

export const AgentAccountCreate: React.FC = () => {
  const { saveButtonProps, getInputProps } = useForm<
    ICreateAgent,
    HttpError,
    Record<string, any>
  >({
    initialValues: {
      ...agentData,
    },

    validate: {},
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <form>
        <TextInput
          mt={8}
          label="name"
          placeholder="name"
          {...getInputProps("name")}
        />

        <TextInput
          mt={8}
          label="phoneNumber"
          placeholder="phoneNumber"
          {...getInputProps("phoneNumber")}
        />

        <TextInput
          mt={8}
          label="agentNumber"
          placeholder="agentNumber"
          {...getInputProps("agentNumber")}
        />

        <TextInput
          mt={8}
          label="balance"
          placeholder="balance"
          {...getInputProps("balance")}
        />
      </form>
    </Create>
  );
};
