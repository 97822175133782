import React from "react";
import { CrudFilters, useTable } from "@pankod/refine-core";

import {
  ScrollArea,
  List,
  Table,
  EditButton,
  Group,
  ShowButton,
} from "@pankod/refine-mantine";

type ChildrenFunction = {
  setFilters: ((filters: CrudFilters) => void) &
  ((setter: (prevFilters: CrudFilters) => CrudFilters) => void)

}

export const CustomTable: React.FC<{
  fields: string[];
  customButtons?: (data: any) => React.ReactNode[];
  identifier?: string;
  children?: ((data: ChildrenFunction) => React.ReactNode) | React.ReactNode;
  filter?: {
    sortBy?: string;
    sortOrder?: "asc" | "desc";
    dateStart?: string;
    dateEnd?: string;
  };
  initialFilter?: CrudFilters;
}> = ({ children, fields, initialFilter, customButtons, identifier = 'id', filter }) => {
  const { tableQueryResult,
    current,
    setCurrent,
    pageSize,
    setPageSize,
    pageCount, //total
    setFilters
  } = useTable({
    permanentFilter: initialFilter,
    metaData: {
      fields,
      variables: {
        ...filter
      }
    },

  },);


  // Checks if there is a next page available
  const hasNext = current < pageCount;
  // Checks if there is a previous page available
  const hasPrev = current > 1;

  return (
    <List canCreate>
      {typeof children === "function" ? children({ setFilters }) : children}
      <ScrollArea>
        <Table verticalSpacing={'md'} highlightOnHover>
          <thead>
            <tr>
              {fields.map((key) => {
                if (typeof key === "string") {
                  return <th>{key}</th>;
                }
              })}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableQueryResult.data?.data.map((row: any) => {
              return (
                <tr key={row.id}>
                  {fields.map((key) => {
                    if (key.includes("expiry") || key.includes('createdAt')) {
                      return <td>{new Date(row[key]).toLocaleString()}</td>
                    }

                    if (key.includes("balance") || key.includes("amount")) {
                      return <td>{row[key] / 100}</td>
                    }
                    return <td>{row[key]}</td>;
                  })}
                  <Group spacing="md" noWrap>
                    <ShowButton hideText recordItemId={row[identifier]} />
                    <EditButton hideText recordItemId={row[identifier]} />
                    {
                      customButtons && customButtons(row)?.map((button) => {
                        return button;
                      })
                    }
                  </Group>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <div>
            <button onClick={() => setCurrent(1)} disabled={!hasPrev}>
              First
            </button>
            <button
              onClick={() => setCurrent((prev) => prev - 1)}
              disabled={!hasPrev}
            >
              Previous
            </button>
            <button
              onClick={() => setCurrent((prev) => prev + 1)}
              disabled={!hasNext}
            >
              Next
            </button>
            <button
              onClick={() => setCurrent(pageCount)}
              disabled={!hasNext}
            >
              Last
            </button>
          </div>
          <span>
            Page{" "}
            <strong>
              {current} of {pageCount}
            </strong>
          </span>
          <span>
            Go to page:
            <input
              type="number"
              defaultValue={current + 1}
              onChange={(e) => {
                const value = e.target.value
                  ? Number(e.target.value)
                  : 1;
                setCurrent(value);
              }}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              const value = e.target.value
                ? Number(e.target.value)
                : 10;
              setPageSize(value);
            }}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>

      </ScrollArea>
      <br />
      {/* <Pagination position="right" total={100} page={1} onChange={() => {}} /> */}
    </List>
  );
};
