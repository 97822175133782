import { HttpError, useList, useUpdate } from "@pankod/refine-core";
import { Edit, TextInput, useForm, Title, Text, Divider, Container, Select, Button, TextField } from "@pankod/refine-mantine";
import { ICreateAgent } from "./create";
import { AgentPermission } from "components/agents/AgentPermission";
import { useEffect, useState } from "react";
import { AgentThirdPartyWallets } from "./components/agentThirdPartyWallets";
type FeaturePermission = {
    featureId: string
    permissions: string[]
}

type ThirdPartyProviderType = 'EDAHAB' | 'ZAAD'
export type IUpdateAgent = Omit<
    ICreateAgent,
    "email" | "agentNumber"
> & {
    agentAccount: {
        agentNumber: string;
        status: string;
        id: string;
    },
    metadata: {
        autoRefund: boolean;

        autoRevert: boolean;

        autoProviderLimit: boolean;
        providers: {
            [key in ThirdPartyProviderType]: {
                status: 'ACTIVE' | 'INACTIVE';
            };
        };
    }
    permissions: FeaturePermission[]
};

type FeatureStringPermission = {
    featureId: string
    permissions: string
}

const initialPermissions: FeatureStringPermission[] = [{
    featureId: "DEPOSIT",
    permissions: ["CREATE", "READ", "UPDATE",].join(","),
}, {
    featureId: "WITHDRAWAL",
    permissions: ["CREATE", "READ", "UPDATE"].join(","),
}]

export const AgentAccountEdit: React.FC = () => {
    const { mutate } = useUpdate({

    });

    const [permissions, setPermissions] = useState<FeatureStringPermission[]>(initialPermissions)

    const [amountLimit, setAmountLimit] = useState({
        USD: 0,
        SLSH: 0

    })
    const {
        saveButtonProps,
        getInputProps,
        refineCore: { queryResult },
    } = useForm<IUpdateAgent, HttpError>({
        initialValues: {
            name: "",
            phoneNumber: "",
            agentAccount: {
                agentNumber: "",
                id: "",
                status: "ACTIVE"
            },

        },
        refineCoreProps: {
            queryOptions: {
                retry: 3,
            },
            metaData: {
                fields: [
                    "id",
                    "name",
                    "phoneNumber",
                    {
                        metadata: ["autoRefund", "autoRevert", "autoProviderLimit", "providers"]
                    },
                    {
                        agentAccount: ["agentNumber", "id","status"],
                    }
                ],
            },
        },

        validate: {},
        transformValues(values: any) {
            //remove agentId
            delete values.agentAccount.id
            return {
                ...values,
                permissions: permissions.map((permission) => {
                    return {
                        featureId: permission.featureId,
                        permissions: permission.permissions.split(",").filter((item) => item !== "")
                    }
                })
            };
        },
    });

    const { data: permissionsDataResult, isLoading } = useList<{
        featureId: string
        permissions: string[]
    }>({
        resource: "agentPermissions",
        config: {
            hasPagination: false,
            filters: [{
                field: "agentId",
                operator: "eq",
                value: queryResult?.data?.data?.agentAccount?.id
            }]
        },

        metaData: {

            fields: ["featureId", "permissions"],
            variables: {
                all: true,
            }
        },
    },);

    const { data: amountLimitResult, isLoading: amountLimitQueryIsLoading } = useList<{
        currency: 'USD' | 'SLSH'
        amountLimit: number
    }>({
        resource: "amountLimit",
        config: {
            hasPagination: false,
            filters: [{
                field: "agentId",
                operator: "eq",
                value: queryResult?.data?.data?.agentAccount?.id
            }]
        },
        metaData: {
            fields: ["currency", "amountLimit"],
        },
    },);




    useEffect(() => {
        if (!isLoading && permissionsDataResult?.data && permissionsDataResult?.data.length > 0) {
            setPermissions(permissionsDataResult?.data.map((item) => {
                return {
                    featureId: item.featureId,
                    permissions: item.permissions.join(",")
                }
            }))
        }
    }, [isLoading, permissionsDataResult?.data])


    useEffect(() => {
        if (!amountLimitQueryIsLoading && amountLimitResult?.data && amountLimitResult?.data.length > 0) {

            const usd = amountLimitResult?.data.find((item) => item.currency === 'USD')

            const slsh = amountLimitResult?.data.find((item) => item.currency === 'SLSH')

            const usdAmountLimit = usd?.amountLimit || 0
            const slshAmountLimit = slsh?.amountLimit || 0

            setAmountLimit({
                USD: usdAmountLimit === -1 ? -1 : usdAmountLimit / 100,
                SLSH: slshAmountLimit === -1 ? -1 : slshAmountLimit / 100
            })
        }
    }, [amountLimitQueryIsLoading, amountLimitResult?.data])


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="name"
                    placeholder="name"
                    defaultValue={queryResult?.data?.data?.name}
                    {...getInputProps("name")}
                />

                <TextInput
                    mt={8}
                    label="phoneNumber"
                    placeholder="phoneNumber"
                    defaultValue={queryResult?.data?.data?.phoneNumber}
                    {...getInputProps("phoneNumber")}
                />

                <TextInput
                    mt={8}
                    label="agentNumber"
                    placeholder="agentNumber"
                    defaultValue={
                        queryResult?.data?.data?.agentAccount.agentNumber
                    }
                    {...getInputProps("agentNumber")}
                />
                <Title my={15}>Agent Status</Title>
                <div className="space-y-4">
                    <div className="flex space-x-2 items-center"
                    >
                        <TextField value='Status' />
                        <Button
                            onClick={() =>
                                mutate({
                                    resource: "agentStatus",
                                    id: queryResult?.data?.data?.agentAccount.id || "",
                                    values: {
                                        status: queryResult?.data?.data.agentAccount.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
                                    },
                                    metaData: {
                                        operation: 'updateAgentStatus',
                                        variables: {
                                            name: 'updateAgentStatusInput'

                                        }
                                    }
                                })
                            }
                            variant="outline"
                        >{queryResult?.data?.data.agentAccount.status}</Button>
                    </div>
                </div>
                <Title my={15}>Providers Status</Title>
                <div className="space-y-4">

                    <div className="flex space-x-2 items-center"
                    >
                        <TextField value='EDAHAB' />
                        <Button
                            onClick={() =>
                                mutate({
                                    resource: "providerStatus",
                                    id: queryResult?.data?.data?.agentAccount.id || "",
                                    values: {
                                        provider: 'EDAHAB',
                                        status: queryResult?.data?.data.metadata.providers.EDAHAB?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
                                    },
                                    metaData: {
                                        operation: 'updateProviderStatus',
                                        variables: {
                                            name: 'updateProviderStatusInput'

                                        }
                                    }
                                })
                            }
                            variant="outline"
                        >{queryResult?.data?.data.metadata.providers.EDAHAB?.status}</Button>
                    </div>
                    <div className="flex space-x-2 items-center"
                    >
                        <TextField value='ZAAD' />
                        <Button
                            onClick={() =>
                                mutate({

                                    resource: "providerStatus",

                                    id: queryResult?.data?.data?.agentAccount.id || "",
                                    values: {
                                        provider: 'ZAAD',
                                        status: queryResult?.data?.data.metadata.providers.ZAAD?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
                                    },
                                    metaData: {

                                        operation: 'updateProviderStatus',
                                        variables: {
                                            name: 'updateProviderStatusInput'

                                        }
                                    }
                                })
                            }
                            variant="outline"
                        >{queryResult?.data?.data.metadata.providers.ZAAD?.status}</Button>
                    </div>

                </div>

                <Title my={15}>Amount Limit</Title>
                <div className="space-y-4">
                    <div className="flex space-x-2 items-center">
                        <TextInput
                            label="USD"
                            placeholder="0"
                            defaultValue={amountLimit.USD}
                            value={amountLimit.USD}
                            type="number"
                            onChange={(e) => {
                                setAmountLimit({
                                    ...amountLimit,
                                    USD: Number(e.target.value)
                                })
                            }}

                        />

                        <Button
                            onClick={() =>
                                mutate({
                                    resource: "amountLimit",
                                    id: queryResult?.data?.data?.agentAccount.id || "",
                                    values: {
                                        currency: 'USD',
                                        amountLimit: amountLimit.USD,
                                    },
                                    metaData: {
                                        operation: 'updateAmountLimit',
                                    }
                                })
                            }
                            variant="outline"
                        >Update USD Limit</Button>
                    </div>
                    <div className="flex space-x-2 items-center">
                        <TextInput
                            label="SLSH"
                            placeholder="0"
                            defaultValue={amountLimit.SLSH}
                            value={amountLimit.SLSH}
                            type="number"

                            onChange={(e) => {
                                setAmountLimit({
                                    ...amountLimit,
                                    SLSH: Number(e.target.value)
                                })
                            }}

                        />

                        <Button
                            onClick={() =>
                                mutate({
                                    resource: "amountLimit",
                                    id: queryResult?.data?.data?.agentAccount.id || "",
                                    values: {
                                        currency: 'SLSH',
                                        amountLimit: amountLimit.SLSH,
                                    },
                                    metaData: {
                                        operation: 'updateAmountLimit',

                                    }
                                })
                            }
                            variant="outline"
                        >Update SLSH Limit</Button>
                    </div>

                </div>

                <AgentThirdPartyWallets id={queryResult?.data?.data?.agentAccount?.id ||''} />


                <Title my={15}>Agent feature permissions</Title>
                {
                    permissions.map((permission, index) => {
                        return (
                            <AgentPermission
                                name={permission.featureId}
                                permissions={permission.permissions}
                                setPermissions={(permissions) => setPermissions((prev) => {
                                    const newPermissions = [...prev]
                                    newPermissions[index].permissions = permissions
                                    return newPermissions
                                })}
                                key={index} />
                        )
                    }
                    )
                }
            </form>
        </Edit >
    );
};

/**
 * agent third party wallets
 * 
 * telegram account
 * 
 * telesom account
 * telesom sub account
 */