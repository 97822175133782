import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
  query (
    $sortBy: String
    $sortOrder: String
    $sort: String
    $where: JSON
    $start: Int
    $limit: Int
  ) {
    accounts(
      sortBy: $sortBy
      sortOrder: $sortOrder
      sort: $sort
      where: $where
      start: $start
      limit: $limit
    ) {
      accountId
      accountType
      phoneNumber
      name
      status
      createdAt
    }
  }
`;
