import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CustomTable } from "components/CustomTable";

export type IWallet = {
  walletId: string;
  accountId: string;
  currencyId: string;
  balance: number;
};
const walletFields: IWallet = {
  walletId: "",
  accountId: "",
  currencyId: "",
  balance: 0,
};
const walletFieldKeys = Object.keys(walletFields).map((key) => {
  return key;
});
export const WalletList: React.FC<IResourceComponentsProps> = () => {
  return <CustomTable fields={walletFieldKeys} />;
};
