import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CustomTable } from "components/CustomTable";

export enum ProviderNameType {
  EDAHAB,
  ZAAD,
  SOLTELCO
}
export enum CurrencyIdType {
  USD,
  SLSH
}
export enum InternetPackageType {
  NORMAL,
  WEEKLY,
  MONTHLY,
  DAILY,
  UNLIMITED,
}
export type InternetPackage = {
  id: string;
  name: string;
  price: number;
  type: InternetPackageType;
  currencyId: CurrencyIdType;
  providerName: ProviderNameType;
  ussdCode: string;
};

export const internetPackageFields: InternetPackage = {
  id: "",
  name: "",
  price: 0,
  type: InternetPackageType.NORMAL,
  currencyId: CurrencyIdType.SLSH,
  providerName: ProviderNameType.EDAHAB,
  ussdCode: "",
};
const internetPackageFieldKeys = Object.keys(internetPackageFields).map(
  (key) => {
    return key;
  }
);

export const InternetPackageList: React.FC<IResourceComponentsProps> = () => {
  return <CustomTable identifier="id" fields={internetPackageFieldKeys} />;
};
