import { gql } from "@apollo/client";

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      accountId
      accountType
      name
      phoneNumber
      status
      createdAt
      wallets {
        walletId
        currency {
          code
        }
        balance
      }
    }
  }
`;
