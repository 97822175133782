import { gql } from "@apollo/client";

export const UPDATE_EXCHANGE_RATE = gql`
  mutation updateExchangeRate($input: UpdateExchangeRateInput!) {
    updateExchangeRate(input: $input) {
      id
      fromCurrencyId
      toCurrencyId
      rate
      createdAt
    }
  }
`;
