import { gql } from "@apollo/client";

export const CREATE_EXCHANGE_RATE = gql`
  mutation createExchangeRate($input: CreateExchangeRateInput!) {
    createExchangeRate(input: $input) {
      id
      fromCurrencyId
      toCurrencyId
      rate
      createdAt
    }
  }
`;
